.correct-answer {
    background-color: rgb(152, 243, 152) !important;
  }
  
  .wrong-answer {
    background-color: rgb(249, 126, 126) !important;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .choice {
    margin-left: 100px; 
  }
  .expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    padding: 0.5rem;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .countdown-timer {
    display: flex;
    align-items: left;
    justify-content: left;
    height: 15vh;
  }
  
  .timer {
    font-size: 18px;
    text-align: center;
  }
  
  .timer span {
    display: inline-block;
    padding: 0.5rem;
    background-color: #333;
    color: #fff;
    margin: 0 0.25rem;
    border-radius: 4px;
  }
  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    background-color: #e6e6e6;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  .score-text{
    color: #6188e2; 
    font-size: xx-large;
  }