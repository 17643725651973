/* Profile Header Section */
.profile-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .profile-name {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .profile-speciality {
    font-size: 18px;
    color: #777;
    font-style: italic;
    margin-bottom: 15px;
  }
  
  .contact-info p {
    font-size: 16px;
    color: #555;
    margin: 8px 0;
  }
  
  .contact-info a {
    text-decoration: none;
    color: #0073b1;
  }
  
  .contact-info i {
    color: #0073b1;
  }
  
  /* Profile Section Styling */
  .profile-section {
    margin-bottom: 25px;
  }
  
  .profile-section h3 {
    font-size: 20px;
    color: #333;
    border-bottom: 2px solid #0073b1;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .profile-section ul {
    list-style: none;
    padding-left: 0;
  }
  
  .profile-section li {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  
  }
  
  .profile-section p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
  }
  
  /* Styling for Icons */
  .profile-header .fas, .profile-header .fab {
    margin-right: 10px;
    color: #0073b1;
  }
  
  .profile-header .fas:hover, .profile-header .fab:hover {
    color: #005a8b;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-card {
      padding: 15px;
    }
  
    .profile-name {
      font-size: 24px;
    }
  
    .profile-speciality {
      font-size: 16px;
    }
  
    .profile-section h3 {
      font-size: 18px;
    }
  
    .profile-section li, .profile-section p {
      font-size: 14px;
    }
  }
  