.test-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.test-list-header {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

.test-list-title {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}


.test-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); 
  gap: 30px;
}


.test-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.test-card:hover {
  transform: translateY(-5px);
}


.test-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 30px; 
  text-align: left; 
  position: relative; 
}

.test-card-icons {
  display: flex;
  justify-content: center;  
  align-items: center;
  position: absolute; 
  bottom: 20px; 
  left: 0; 
  right: 0;
  gap: 25px; }

.test-card-title {
  font-size: 22px; 
  margin: 0;
  color: #333;
}

.test-card-description {
  flex-grow: 1; /* Remplit l'espace disponible */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limite à 3 lignes */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.read-more-link {
  color: #000000;
  cursor: pointer;
  margin-left: 5px;
}
