.custom-row {
    max-width: 1200px;
    margin: 0 auto; /* Pour centrer le cadre horizontalement */
  }
.tri-btn {
    border: 2px solid #43dac1; 
    border-radius: 50%; 
    background-color: rgb(202, 255, 246);
    padding: 5px; 
    width: 50px;
   
  }
  