@keyframes flash {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  .download-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  
    &:hover {
      background-color: #e24c4c;
    }
  }
  
  .flash {
    animation: flash 2.5s infinite;
  }
  