
.interview-report {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px auto;
    max-width: 1200px;
}

.interview-report .card-title {
    color: #2c3e50;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.5rem;
}

.interview-report .card-text {
    color: #34495e;
    font-size: 1rem;
    line-height: 1.6;
}

.interview-report .questions .card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1rem;
    transition: box-shadow 0.3s ease;
}

.interview-report .questions .card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.interview-report .snapshots .thumbnail {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
    transition: transform 0.3s ease;
}

.interview-report .snapshots .thumbnail:hover {
    transform: scale(1.05);
}

.interview-report .progress-bar {
    background-color: #3498db;
    border-radius: 5px;
}

.interview-report .progress {
    height: 20px;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.interview-report .row {
    margin-bottom: 1rem;
}

.interview-report .col {
    padding: 0.5rem;
}

.interview-report .btn-primary {
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.interview-report .btn-primary:hover {
    background-color: #2980b9;
}

.interview-report .alert {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.interview-report .alert-warning {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}


.interview-report .snapshots p {
    color: #e74c3c;
    font-weight: bold;
}
