.informationScore {
  all: unset;
  background: #00aaff;
  color: white;
  border-radius: 50%; /* Use 50% for a circular shape */
  font-size: 1.2rem; /* Increase the font size to make it larger */
  width: 1.4rem; /* Increase the width and height to make it larger */
  height: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: italic;
  margin-left: 10px;
  margin-bottom: 7px;
  padding: 0;
  transition: background-color 0.3s;
}

.informationScore {
  background-color: #0088ff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  margin-left: 5px;
  padding: 0;
}
.buttonAline {
  display: flex;
  align-items: center; /* Align items vertically if needed */
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-message {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
  width: 200px;
  top: 20px; /* Adjust this value as needed */
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip-message {
  display: block;
}






 .select-container {
    display: flex;
   
  }
  
  .select-wrapper-left {
    flex: 1;
    margin-right: 10px;
 
  
  }
  .select-wrapper-right {
    flex: 1;
    margin-right: 10px;
   
  }
  .input-button-container {
    display: flex;
    align-items: center;
  }
  .left-align-container {
    display: flex ;
   
    align-items:flex-start;
    margin-bottom: 5px;
    
  }
  .skills-container {
  display: inline-block;
}
  .specialism-tag {
    display: inline-block !important;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 5px;
    background-color: #43DAC2;
    color: rgb(255, 255, 255);
    border-radius: 20px;
    font-size: 13px;
    font-weight: bold;
  }
  .buttonSkills{
    margin-left: 10px;
    background-color: rgb(21, 193, 21);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 37px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;}
    .buttonSkills:hover{background-color: black;}
    /* Media query for responsiveness */
    @media (max-width: 600px) {
      
      .smallscreen{
        font-size: 12px;
        flex-direction: column;
      }
      .select-container {
        flex-direction: column;
       
       
      }

      .select-wrapper-left {
        flex: 1;
        margin-right: 10px;
        margin-bottom: 10px;
     
      
      }
      .select-wrapper-right {
        flex: 1;
        margin-right: 10px;
        margin-bottom: 10px;
       
      }
     
    }
    .max-widht {
      
      max-width: 1000px; 
      margin: 0 auto; 
    }

