
  
  .modalOuter {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .modalBox {
    width: 50%;
    margin: 0 ;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
  
  .heading,
  .drag {
    font-weight: 600;
    color: #191442;
  }
  
  .drag {
    margin-top: 1em;
    margin-bottom: 0.5em;
    cursor: pointer;
  }
  
  .drag .browse {
    display: inline-block;
    color: #86E6D7;
  }
  
  .file-label {
    cursor: pointer;
  }
  
  .instruction,
  .info {
    color: #6f6c78;
  }
  
  .heading {
    font-size: 25px;
  }
  
  .instruction {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 14px;
  }
  
  .drag {
    font-size: 18px;
  }
  
  .info {
    font-size: 12px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 99; /* Ensure the overlay appears above other content */
  }
  
  .uploadBox {
    padding: 2em;
    max-width:  100%;

    border: 2px dashed #86E6D7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /* First and Second Tab Styles */
  
  .upload-icon {
    font-size: 2em;
    color: #86E6D7;
  }
  
  .fileIcon {
    display: none;
  }
  
  .filename {
    font-size: 12px;
  }
  






  .loading-wave {
    max-width: 300px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .loading-bar {
    width: 20px;
    height: 10px;
    margin: 0 5px;
    background-color: #86E6D7;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
  }
  
  .loading-bar:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-bar:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .loading-bar:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  @keyframes loading-wave-animation {
    0% {
      height: 10px;
    }
  
    50% {
      height: 50px;
    }
  
    100% {
      height: 10px;
    }
  }
  