.education-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .education-input,
  .education-textarea {
    padding: 8px;
    margin: 4px 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .education-textarea {
    resize: vertical;
    height: 50%;
  }
  

.general-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .info-input,
  .experience-input,
  .section-input {
    padding: 8px;
    margin: 4px 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  
  .drag-handle {
    cursor: grab;
    margin-top: 8px;
    color: #999;
  }
  
/* src/components/CVPreview.css */
.preview {
    padding: 20px;
    margin-left: 20px;
    width: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .preview-section {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .preview-experience {
    margin-bottom: 10px;
  }
  .delete-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #ff1a1a;
  }

  
  .editor {
    overflow-y: auto;
    padding: 10px;
  }
  .toggle-radio {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .toggle-radio label {
    margin-right: 15px;
    cursor: pointer;
  }
 
  .info-input,
  .language-input,
  .section-input {
    padding: 8px;
    margin: 4px 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .section {
    transition: box-shadow 0.2s ease;
    padding: 16px; 
    margin: 10px;
    border-radius: 8px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

  .section:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }
  
  .section:active {
    opacity: 0.8; 
  }
  .experience-entry {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
  }
  
  .experience-entry .form-group label {
    font-weight: 500;
    color: #333;
  }
  
  .experience-entry input.form-control {
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .experience-entry button[aria-label="Supprimer"] {
    color: #d9534f;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .experience-entry button[aria-label="Supprimer"]:hover {
    color: #c9302c;
  }
  
  /* educatiion */
  .education-entry {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
  }
  
  .education-entry .form-group label {
    font-weight: 500;
    color: #333;
  }
  
  .education-entry input.form-control {
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .education-entry button[aria-label="Supprimer"] {
    color: #d9534f;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .education-entry button[aria-label="Supprimer"]:hover {
    color: #c9302c;
  }
  
  /* language */
  .language-entry {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
  }
  
  .language-entry .form-group label {
    font-weight: 500;
    color: #333;
  }
  
  .language-entry input.form-control {
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .language-entry button[aria-label="Supprimer"] {
    color: #d9534f;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .language-entry button[aria-label="Supprimer"]:hover {
    color: #c9302c;
  }
  
  /* cert */
  .cert-entry {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
  }
  
  .cert-entry .form-group label {
    font-weight: 500;
    color: #333;
  }
  
  .cert-entry input.form-control {
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .cert-entry button[aria-label="Supprimer"] {
    color: #d9534f;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .cert-entry button[aria-label="Supprimer"]:hover {
    color: #c9302c;
  }
  
  /* vol */
  .vol-entry {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
  }
  
  .vol-entry .form-group label {
    font-weight: 500;
    color: #333;
  }
  
  .vol-entry input.form-control {
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .vol-entry button[aria-label="Supprimer"] {
    color: #d9534f;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .vol-entry button[aria-label="Supprimer"]:hover {
    color: #c9302c;
  }