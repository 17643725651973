/* CandidateData.css */
.candidate-data-container {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
/*   table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  select {
    width: 100%;
    padding: 6px;
    border-radius: 4px;
  } */