.user-resumes {
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .resumes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .resume-card {
    background-color: white;
    border: none;
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    transition: transform 0.2s ease; 
    overflow: hidden; 
    height: auto;
  }
  
  .resume-card:hover {
    transform: translateY(-5px);
  }
  
  .resume-header {
    display: flex;
    align-items: center; 
    padding: 15px;
    background-color: #e9ecef; 
  }
  
  .resume-photo {
    width: 60px; 
    height: 60px; 
    border-radius: 50%;
    margin-right: 15px; 
  }
  
  .resume-placeholder-photo {
    width: 60px; 
    height: 60px;
    background-color: #ccc; 
    border-radius: 50%; 
    margin-right: 15px;
  }
  
  .resume-info {
    flex: 1; 
  }
  
  .button-group {
    margin-top: 15px;
  }
  
  .button-group .btn {
    width: 100%; 
  }
  