.job-profile {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px; 
    margin: 0 auto; 
  }
  
  .job-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .coefficients-form {
    margin-top: 20px;
  }
  
  .coefficients-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    gap: 20px;
  }
  
  .coefficient-item {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
     max-width: 100%;
  }
  
  .qualification-label {
    font-weight: bold;
    margin-bottom: 6px;
  }
  
  .coefficient-input {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
.button-container {
    text-align: right;
    margin-top: 15px;
  }
  
  .loading-message {
    font-style: italic;
    color: #999;
    margin-top: 20px;
  }
  @media screen and (min-width: 768px) {
    .coefficients-list {
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  