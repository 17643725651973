.job-container {
  max-width: 900px;
  margin: auto;
}

.job-title {
  color: #343a40;
  font-size: 1.8rem;
}

.job-header .company-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-details p {
  margin: 0.5rem 0;
  font-size: 1.25rem;
}

.badge-primary {
  background-color: #007bff;
  color: white;
}

.badge-success {
  background-color: #28a745;
  color: white;
}

.badge-danger {
  background-color: #dc3545;
  color: white;
}

.badge-warning {
  background-color: #ffc107;
  color: black;
}

.badge-light {
  background-color: #f8f9fa;
  color: #6c757d;
}
.job-info-item {
  display: flex;
  align-items: center;
  margin: 12px 0; 
  font-size: 1.1rem; 
}

.job-icon {
  margin-right: 10px;
  color: #74188D; 
}
