/* PolitiqueConfidentialite.css */

/* Conteneur principal */
.politique-confidentialite-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Titres principaux */
  .politique-confidentialite-container h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .politique-confidentialite-container h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  /* Paragraphes */
  .politique-confidentialite-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Listes */
  .politique-confidentialite-container ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #555;
  }
  
  .politique-confidentialite-container ul li {
    margin-bottom: 10px;
  }
  
  /* Liens */
  .politique-confidentialite-container a {
    color: #43DAC2;
    text-decoration: none;
  }
  
  .politique-confidentialite-container a:hover {
    text-decoration: underline;
  }
  
  /* Section Contact */
  .politique-confidentialite-container ul li strong {
    color: #333;
  }
  
  /* Stylisation de la date de mise à jour */
  .politique-confidentialite-container p:last-child {
    font-size: 0.9rem;
    color: #777;
    text-align: center;
    margin-top: 40px;
  }
  
  /* Section pour la liste des catégories de cookies et informations supplémentaires */
  .cookie-category {
    background-color: #ecf0f1;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .cookie-category h5 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .cookie-category p {
    font-size: 1rem;
    color: #555;
  }
  