

/* Container */
.profile-list-header {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
}
.profile-list-title {
  font-size: 28px;
  margin: 0;
  text-align: center;
}
.job-profile-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header */
.job-profile-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center the title */
}

/* List of Profiles */
.job-profile-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Profile Card */
.job-profile-card {
  position: relative; /* Make the container a positioning context for absolute positioning */
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: calc(40.33% - 20px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  min-height: 400px; /* Adjust this as needed */
  margin-bottom: 20px; /* Add margin between cards */
  /* Add more styles as needed */
}

/* .job-profile-card:hover {
  transform: scale(1.05);
} */

.position-icon-list-profiles {
  position: absolute; /* Position icons absolutely within the card */
  bottom: 5px; /* Adjust this if you want some space between icons and the bottom */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 20px; /* Add padding to create space between icons and card content */
}

.job-profile-title  {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Profile Details */
.job-profile-details  {
  font-size: 14px;
  margin-bottom: 10px;
}

/* Qualifications Tags */
.specialism-tag-profile {
  display: inline-block;
  background-color: #43DAC2;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  margin-right: 5px;
  border-radius: 4px;
  margin-bottom: 5px; /* Add margin bottom to skills */
}


/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .job-profile-card {
    width: 100%;
  }
}
