
.Onboarding {
    font-family: Arial, sans-serif;
    margin: 20px;
    display: flex; /* Set display to flex for layout */

  }
  .single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    white-space: nowrap;
  }
  

  .import {
    margin-bottom: 20px;
    position: sticky;
    top: 0px;
    justify-content: space-between;
    align-content: center;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    height: 70px;
    background-color: white;
    z-index: 4;

  }
  
  

  .import h2 {

    margin: 20px;
    height: fit-content;

  }







  .category {
    margin-bottom: 20px;
  }
  
  .category h2 {
    cursor: pointer;
    background-color: #86E6D7;
    padding: 10px;
    
  }
  
  .category-content {
    background-color: #e8faf7;
    padding: 10px;
    border: 1px solid #74188D;

    display: flex; /* Set display to flex for vertical alignment */
  flex-direction: column; /* Arrange form fields vertically */
  }
  .experience-block {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #74188D;

    margin-bottom: 10px;
    display: flex; /* Set display to flex for vertical alignment */
  flex-direction: column; /* Arrange form fields vertically */
  }




  .btnOnboard {
    margin-top: 20px;
  }
  
  
  .side-image {
    height: 97vh; /* Full height of the viewport */
    width: 25%; /* 25% of the viewport width */
    object-fit: cover; /* Ensure the image covers the entire container */
    border-radius: 16px;
    margin-right: 20px;
    position: fixed; /* Fixed positioning */
  top: 10px; /* Align to the top of the viewport */
  left: 10px; /* Align to the left of the viewport */
    flex-shrink: 0; /* Prevent the image from shrinking */
    z-index: -1;
  }

  body.overshrunk .side-image {
    display: none;
  }
  
  body.overshrunk .formOnboard {
    margin-left: 2vw; /* Leave space for the fixed image */
    margin-top: 8vw; /* Leave space for the fixed image */
  }

  .logo-image {
    object-fit: cover; /* Ensure the image covers the entire container */
    margin-right: 20px;
    max-width: 15%;
    position: fixed; /* Fixed positioning */
  top: 20px; /* Align to the top of the viewport */
  z-index:  3; /* Place logo on top of other elements */
  left: 10px; /* Align to the left of the viewport */
    flex-shrink: 0; /* Prevent the image from shrinking */
  }








  
  .linkenIn-import-data {
    height: 20px; /* Full height of the viewport */
    width: 80px; /* 25% of the viewport width */
    object-fit: cover; /* Ensure the image covers the entire container */
    margin-right: 20px;
  }


  .formOnboard {
    margin-left: 28vw; /* Leave space for the fixed image */

    width: 72%; /* Occupy the remaining 75% of the viewport */
  
  }

  .inputOnboard{
    margin-bottom: 10px; /* Add space between input fields */
    height: 40px;
    width: 90%;
    cursor: pointer;
   border: 2px solid #74188D;
    border-radius: 5px;
    font-size: 16px;
    color: #555;
    outline: none;}

  
  .inputOnboard:focus {
    border-color: #74188D;
    box-shadow: 0 0 0 0.2rem #74188D5c;
  }

 

  .select-style {
    padding: 10px;
    border: 2px solid #74188D;
    border-radius: 5px;
    font-size: 16px;
    color: #555;
    outline: none;
    margin-bottom: 10px; /* Add space between input fields */
    height: 40px;
    width: 100%;
  }
  
  .select-style:focus {
    border-color: #74188D;
    box-shadow: 0 0 0 0.2rem #74188d7b
  }


  .experience-block {
    position: relative;
  }
  
  .remove-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px;
  }

  .btnOnboard {
    position: relative;
    display: inline-block;
    margin: 15px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #74188D;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #74188D;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #74188D;
  }
  
  .btnOnboard:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #74188D;
  }
  
  .btnOnboard:active {
    transform: scale(0.9);
  }


  /* Add a new class for the selected state */
.selected {
  background-color: #74188D;
  color: white;
  box-shadow: inset 0 -100px 0 0 #74188D;
  position: relative;
    display: inline-block;
    text-align: center;
    font-size: 18px;

    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #74188D;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #74188D;
}

/* Apply hover effect to the selected state */
.selected:hover {
  background: transparent;

  box-shadow: inset 0 -100px 0 0 #74188D;
  position: relative;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #74188D;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #74188D;
}

.notselected {
  color: #74188D;
  box-shadow: inset 0 -100px 0 0 #74188D;
  position: relative;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #74188D;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #74188D;
}


/* Apply hover effect to the selected state */
.notselected:hover {
  background-color: #eee;
  color: white;
  box-shadow: inset 0 -100px 0 0 #74188D;
}






.checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-left: 10px;
}

.checkbox-wrapper .checkbox-svg {
  width: 20px;
  height: 20px;
}

.checkbox-wrapper .checkbox-box {
  fill: #86E6D7;
  stroke: #74188D;

  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.2s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #74188D;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.2s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}











.radio-button-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  align-items: center;

}

.radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.radio-button__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.radio-button__custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #65549B;
  transition: all 0.3s ease;
}

.radio-button__input:checked + .radio-button__label .radio-button__custom {
  background-color: #65549B;
  border-color: transparent;
  transform: scale(0.8);
  box-shadow: 0 0 20px #65549B;
}

.radio-button__input:checked + .radio-button__label {
  color: #65549B;
}

.radio-button__label:hover .radio-button__custom {
  transform: scale(1.2);
  border-color: #65549B;
  box-shadow: 0 0 20px #65549B;
}






  .standard-btn {
    position: relative;
    display: inline-block;
    margin: 15px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: white;
    background: #74188D;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #74188D;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #74188D;
  }
 
  
  .standard-btn:active {
    transform: scale(0.9);
  }
