
.candidate-interface-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin: 10px !important;
}

.candidate-interface-container .cardResult {
  
  padding: 20px !important;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  width: 100% !important;
  max-width: 800px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.candidate-interface-container .label {
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.candidate-interface-container .select {
  width: 100% !important;
  padding: 8px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  background-color: #f0f8ff !important;
  margin-bottom: 10px !important;
}

.candidate-interface-container .selected-candidate {
  font-size: 14px !important;
  margin-top: 5px !important;
  color: #000 !important;
}

.candidate-interface-container .result-description {
  font-size: 16px !important;
  margin-bottom: 20px !important;
  color: #000 !important;
  border: 1px solid #ccc!important; 
  padding: 20px !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.candidate-interface-container .radar-chart-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.candidate-interface-container .radar-chart {
  background-color: aliceblue !important;
  padding: 20px !important;
  border-radius: 10px !important;
  width: 100% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.candidate-interface-container .please-select-candidate {
  font-size: 16px !important;
  text-align: center !important;
  color: #000 !important;
}

.result-description .personality-type {
  font-size: 18px !important;
  margin-bottom: 10px !important;
}

.acronym {
  font-size: 16px !important;
  margin: 10px auto !important;
  background-color: #45c4e4 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  text-align: center;
}

.title {
  font-size: 18px !important;
  text-align: center;
}


@media (max-width: 768px) {
  .result-description {
    font-size: 14px !important;
    padding: 10px; 
  }
  
  .acronym {
    font-size: 14px !important;
    padding: 5px 10px !important;
  }
  
  .title {
    font-size: 14px !important;
  }
  
  .personality-type {
    font-size: 14px !important;
  }
}
