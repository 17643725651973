.cookie-consent-banner {
    position: fixed;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 1000px;
    height: auto;
    max-height: 900px;
    background: linear-gradient(135deg, #10b99d, #5297c5);
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}

.cookie-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-button {
    background: none;
    border: none;
    color: #b8e4f9;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    transition: color 0.3s;
}

.tab-button.active {
    color: #c193e2;
    border-bottom: 2px solid #8c4baf;
}

.cookie-content {
    flex-grow: 1;
    margin-bottom: 20px;
}

.cookie-category h5 {
    margin-bottom: 5px;
    color: #8c4baf;
}

.cookie-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.accept-btn,
.decline-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    font-size: 14px;
}

.accept-btn {
    background-color: #8c4baf;
    color: #ffffff;
}

.decline-btn {
    background-color: #43DAC2;
    color: #ffffff;
}

.accept-btn:hover {
    background-color: #8c4baf;
}

.decline-btn:hover {
    background-color: #85c7f2;
}
