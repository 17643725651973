/*password progress */
.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: bold;
}
.progress {
  height: 10px;
  border-radius: 5px;
}

.progress-bar {
  transition: width 0.3s ease-in-out;
}

.bg-danger {
  background-color: #dc3545;
}

.bg-warning {
  background-color: #ffc107;
}

.bg-success {
  background-color: #28a745;
}
/* password hint list*/
.password-hint {
  margin-top: 10px;
}

.password-hint ul {
  list-style: none;
  padding: 0;
}

.password-hint li {
  color: #74188D;
}

.password-hint .hint-typed {
  color: #33b9a2;
}

.password-hint .hint-not-typed {
  color: black;
}

.password-hint .hint-icon {
  margin-right: 5px;
}
