.password-input-container {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  /*   import './login.css'; */
   /* Error message */

.error-message {
  background-color: #F87171;
  color: #FFF;
  border: 1px solid #F87171;
  border-width: 2px 1px;
  padding: 10px;
  margin: 10px 0;
}

/* Success message */
.success-message {
  background-color: #34D399;
  color: #FFF;
  border: 1px solid #34D399;
  border-width: 2px 1px;
  padding: 10px;
  margin: 10px 0;
}