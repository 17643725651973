hr{
    width: 870px;
    border: 15px;
  }
  
  h1,h3{
    display: flex;
    flex-direction: column;
      align-items: center;
  }
  .quiz {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .question {
      opacity: 0.5;
      transition: opacity 0.5s ease-in-out;
    }
    .other-question {
      opacity: 0.5;
      transition: opacity 0.5s ease-in-out;
    }
    
    
    .current-question {
      opacity: 1;
    }
    
    .question ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    
    .question ul li {
      margin-right: 20px;
    }
    
    .question ul li label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .question ul li label input[type="radio"] {
      margin-right: 35px;
      margin-left: 40px;
    }
    .label-font-left {
      font-size: 20px;
      color:black;
    }
    .label-font-right {
      font-size: 20px;
      color: #329779;
    }
    
    .radio-btn-1 {
      transform: scale(4.5); 
      accent-color: red;
      
    }
    
    .radio-btn-2 {
      transform: scale(3.5); 
      accent-color: red;
    }
    
    .radio-btn-3 {
      transform: scale(2.5); 
      accent-color: grey;
    }
    .radio-btn-4 {
      transform: scale(3.5); 
      accent-color: #329779;
    }
    .radio-btn-5 {
      transform: scale(4.5); 
      accent-color: #329779;
    }
    .radio-btn-10005 {
      transform: scale(3.5); 
      accent-color: red;
    }
    
    .radio-btn-20005 {
      transform: scale(2.5); 
      accent-color: grey;
    }
    .radio-btn-30005 {
      transform: scale(3.5); 
      accent-color: #329779;
    }