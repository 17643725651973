.personality-test-details {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .test-details-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .test-details-table-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .table th {
    background-color: #f5f5f5;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:hover {
    background-color: #f0f0f0;
  }
  
  .answer-value {
    font-weight: bold;
    color: #43DAC2;
  }
  .test-list-header {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
  }
  .back-button {
    border: none;
    background-color: #e6e6e6;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  