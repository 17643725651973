/* TestCompletion.css */

.test-completion {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .test-completion-content {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
.titre2-completion-test {
    font-size: 36px; 
    color: #43DAC2; 
    margin-bottom: 20px;
  }
  
.text-completion-test {
    font-size: 24px; 
  }
  